export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'de',
    messages: {
        de: {
            global: {
                id: 'Belegnummer',
                date: 'Datum',
                amount: 'Betrag',
                total: 'Total',
                url: 'URL',
                apiKey: 'API-Key',
                activate: 'Aktivieren',
                disconnect: 'Verbindung trennen',
                day: 'Tag',
                dayTotalRevenue: 'Umsatz pro Tag',
                dayTotal: 'Total Zahlungsarten',
                description: 'Beschrieb',
                addEntry: 'Eintrag hinzufügen',
                addPaymentType: 'Zahlungsart hinzufügen',
                address: 'Adresse',
                abort: 'Abbrechen',
                logo: 'Logo',
                dropdown: 'Dropdown',
                applicationUserId: 'Application User ID',
                paymentMethodsDigital: 'Zahlungsarten Digital',
                paymentMethodsManual: 'Zahlungsarten Manuell',
                buttons: {
                    login: 'Login',
                    next: 'Weiter',
                    previous: 'Zurück',
                    finish: 'Abschliessen',
                    cancel: 'Abbrechen',
                    save: 'Speichern',
                    letsgo: 'Los geht\'s',
                    export: 'Exportieren',
                    startSetup: 'Setup Starten',
                    closeDay: 'Tag abschliessen',
                    closedDay: 'Tag abgeschlossen',
                    toOverview: 'Zur Übersicht',
                    closed: 'Abgeschlossen',
                    editDay: 'Tag Editieren',
                },
                consumptionTypes: 'Konsumationsarten',
                outlets: 'Outlets',
                errors: {
                    dailyBalanceUpdateFailed: 'Tagesabschluss konnte nicht aktualisiert werden',
                    dailyBalanceGetFailed: 'Tagesabschluss konnte nicht geladen werden',
                    usernameOrPasswordIncorrect: 'Benutzername oder Passwort ist falsch',
                    fetchUserOrganizations: 'Fehler beim Laden der Organisationen',
                    fetchRestaurantList: 'Fehler beim Laden der Restaurant Liste',
                    fetchRestaurant: 'Fehler beim Laden der Restaurant',
                    fetchUsersList: 'Fehler beim Laden der Benutzer Liste',
                    fetchRolesList: 'Fehler beim Laden der Rollen Liste',
                    fetchUser: 'Fehler beim Laden des Benutzers',
                    saveUser: 'Fehler beim Speichern des Benutzers',
                    userAlreadyExists: 'Benutzer existiert bereits',
                    isRequired: 'Dieses Feld ist ein Pflichtfeld',
                    passwordForgot: 'Ein Fehler ist aufgetreten. Bitte versuche es erneut',
                    passwordReset: 'Ein Fehler ist aufgetreten. Bitte versuche es erneut',
                    allTerminalsShouldBeFilled: 'Nicht alle Terminals sind ausgefüllt!',
                    updateRestaurantsFailed: 'Whoops!!! Something went wrong...',
                    failedToConnectHelloTess: 'Verbindung zu HelloTess! konnte nicht hergestellt werden!',
                    fetchMonthlyOverview: 'Fehler beim Laden der Monatsübersicht',
                    mapRestaurantToStore: 'Restaurant konnte nicht dem Store zugeordnet werden',
                    trusteeSettingsFailed: 'Treuhänderangaben konnten nicht gespeichert werden',
                    accountAlreadyExists: 'Buchungen mit diesem Konto existieren bereits',
                    intercompanyMovementsFailed: 'Der Eintrag konnte nicht gespeichert werden',
                    dayCloseFailed: 'Der Tag konnte nicht abgeschlossen werden',
                    connectInfoniqaError: 'Fehler beim Verbinden von Infoniqa',
                    disconnectInfoniqaError: 'Fehler beim Trennen von Infoniqa',
                },
                successMessages: {
                    restaurantUpdatedSuccessfully: 'Das Restaurant wurde erfolgreich aktualisiert',
                    restaurantPaymentTypesUpdatedSuccessfully: 'Die Zahlungsarten des Restaurants wurden erfolgreich aktualisiert',
                    restaurantPayTerminalsUpdatedSuccessfully: 'Die Zahlungsterminals des Restaurants wurden erfolgreich aktualisiert',
                    hellotessConnectedSuccessfully: 'HelloTess! hat erfolgreich eine Verbindung hergestellt',
                    mapRestaurantToStore: 'Restaurant successfully mapped to the store',
                    trusteeSettingsSaved: 'Treuhänderangaben einstellungen erfolgreich gespeichert',
                    intercompanyMovementsSaved: 'Eintrag erfolgreich gespeichert',
                    dailyBalanceUpdated: 'Tagesabschluss erfolgreich aktualisiert',
                    accountsUpdated: 'Konten erfolgreich aktualisiert',
                    accountCreated: 'Konto erfolgreich erstellt',
                    passwordReset: 'Passwort erfolgreich zurückgesetzt',
                    dayClosed: 'Tag erfolgreich abgeschlossen',
                    connectInfoniqaSuccessfully: 'Infoniqa erfolgreich verbunden',
                    disconnectInfoniqaSuccessfully: 'Infoniqa erfolgreich getrennt',
                },
                role: 'Rolle',
                roles: {
                    owner: 'Owner',
                    restaurant_manager: 'Restaurant Manager',
                    shift_manager: 'Shift Manager',
                    accountant: 'Accountant',
                },
                webhook: {
                    name: 'Name',
                    nameValue: 'Gastrobuddy Invoice Webhook',
                    event: 'Evant',
                    active: 'Active',
                    mode: 'Mode',
                    modeValue: 'Webhook',
                    pushMethod: 'Push Method',
                    pushMethodValue: 'POST',
                    pushUrl: 'Push URL',
                    httpHeaders: 'HTTP Headers',
                    httpHeadersValue: 'Value',
                    security: 'Security',
                    authenticationMode: 'Authentication Mode',
                    addTo: 'Add To',
                    key: 'Key',
                    value: 'Value',
                },
            },
            login: {
                heading: 'Save time, go home earlier!',
                email: 'E-Mail oder Benutzername',
                password: 'Passwort',
                passwordRepeat: 'Passwort wiederholen',
                forgotPassword: 'Passwort vergessen?',
                login: 'Login',
                loginStart: 'Senden',
                register: 'Registrieren',
                tenantChooser: 'Wählen Sie Ihr Restaurant',
                forgotPasswordDescription: 'Um dir ein Link zur Passwort wiederherstellung zu senden brauchen wir deine E-Mail:',
                forgotPasswordButton: 'Senden',
                forgotPasswordSuccess: 'Wir haben dir nun einen Link an deine E-Mail geschickt. Bitte sehe in deiner Inbox nach.',
                resetPasswordDescription: 'Gib nun dein neues Passwort ein:',
                resetPasswordButton: 'Passwort zurücksetzen',
                resetPasswordCancel: 'Abbrechen',
                toLogin: 'Zum Login',
                reset: 'Zurücksetzen',
            },
            emailConfirmation: {
                title: 'Bestätige deine E-Mail',
                text: 'Um deine Registrierung abzuschliessen, bestätige bitte deine E-Mail.',
                sendAgainButton: 'E-Mail erneut senden',
                backButton: 'Zum Login',
            },
            setup: {
                step: 'Schritt',
                start: {
                    title: 'Willkommen bei GastroBuddy!',
                    description: 'In den nächsten Schritten werden wir zusammen deinen Account einrichten.',
                    button: 'Starten',
                },
                tenant: {
                    step: {
                        title: 'Deine Organisation',
                        subtitle: 'Gruppenname',
                        description: 'Falls ein Nutzer mehreren Gruppen hinzugefügt wurde, muss beim Login eine Gruppe ausgewählt werden. Das ist der Gruppenname welcher diese Gruppe repräsentiert.',
                    },
                },
                user: {
                    title: 'Benutzerangaben',
                    step: {
                        title: 'Deinen Benutzeraccount',
                        description: 'In einem ersten Schritt müssen wir deinen Benutzeraccount einrichten.',
                    },
                    labels: {
                        name: 'Name',
                        firstname: 'Vorname',
                        email: 'E-Mail',
                        username: 'Benutzername',
                        password: 'Passwort',
                        passwordRepeat: 'Passwort wiederholen',
                        role: 'Rolle',
                    },
                },
                restaurant: {
                    title: 'Benutzerangaben',
                    step: {
                        title: 'Dein erstes Restaurant',
                        description: 'Nun legen wir dein erstes Restaurant an. Diese Einstellungen können jeder Zeit unter “Restaurant Einstellungen” geändert werden. ',
                    },
                    labels: {
                        name: 'Restaurant Name',
                        street: 'Strasse',
                        streetNumber: 'Nr.',
                        postcode: 'PLZ',
                        city: 'Stadt',
                        country: 'Land',
                        address: 'Adresse',
                        dropzone: {
                            dragAvailable: 'Ziehe ein neues Logo hierhin um das alte zu ersetzen.',
                            dropAvailable: 'Neues Logo hier einfügen.',
                        },
                    },
                },
                paymentMethods: {
                    digitalTitle: 'Zahlungsarten digital',
                    terminal: 'Zahlarten Terminal',
                    cashTitle: 'Zahlungsarten Bar',
                    divers: 'Zahlarten Divers',
                },
                externalSystems: {
                    worldline: {
                        title: 'WorldLine',
                        description: 'Um den Prozess zu starten, must du hier deine Credentials zum Transaction Journal von WorldLine eingeben.',
                        terminals: 'Terminals',
                        addTerminal: 'Terminal hinzufügen',
                        terminalPlaceholder: 'Bezeichnung',
                        category: 'Sparte',
                        fibuAccount: 'FIBU-Kontierung',
                    },
                    hellotess: {
                        title: 'helloTESS! API',
                        description: 'Um den Prozess zu starten musst du hier deinen helloTESS! API Schlüssel einfügen. Diesen kannst du bei {email} anfragen.',
                        activateButton: 'Aktivieren',
                        deactivateButton: 'Löschen',
                        apiKeyLabel: 'API-Key',
                        apiURL: 'Backoffice URL',
                        apiKeyName: 'API-Key Name',
                        apiKeyDescription: 'API-Key Description',
                    },
                    infoniqa: {
                        title: 'Monatsexport',
                        description: 'Exportiere Monatsabschlüsse in deine Finanzbuchhaltung',
                        subtitle: 'Infoniqa ONE 50',
                        text: 'Um den Prozess zu starten musst du hier deinen Infoniqa ONE 50 API-Key und URL einfügen. Diese kannst du bei <a href="mailto:{email}" target="_blank">{email}</a> anfragen.',
                    },
                    wallee: {
                        summary: 'Zusammenfassung',
                        terminals: 'Terminals',
                        paymentMethods: 'Zahlarten',
                        text: 'Verbinde einzelne Terminals mit deinem Wallee-Konto um automatisch Werte zu bekommen. Um die Backoffice URL und den API-Key zu bekommen, wende dich an den Wallee Support.',
                        titlePage1: 'Auf welchem Space sind die Terminals dieses Restaurants vorhanden?',
                        textPage1: 'Bitte wähle den Space aus um mit dem Set-Up Prozess weiterzufahren.',
                        titlePage2Left: 'Es wurden folgende/s ',
                        titlePage2Right: ' Terminal/s gefunden',
                        textPage2Terminals: 'Diese Terminals existieren bereits. Stelle sicher, dass du hier garantiert alle Wallee Terminals erfasst hast.',
                        titlePage3: 'Nun müssen wir die Wallee Terminals den GastroBuddy Terminals zuweisen',
                        textPage3: 'Es müssen alle Terminals welche in deinem Wallee Konto gefunden wurden einem deiner GastroBuddy Terminals hinzugefügt werden. Nicht alle Terminals gefunden? Überprüfe die Einstellungen deiner Wallee Systeme und versuche es nochmals.',
                        methods: 'Definieren wir nun die relevanten Zahlungsarten.',
                        titlePage4Left: 'Es wurde/n folgende ',
                        titlePage4Right: ' Zahlart/en gefunden',
                        textPage4Methods: 'Im nächsten Schritt wirst du die gefundenen Zahlungsarten auf die einzelnen GastroBuddy Zahlungsarten pro Terminal mappen können.',
                        titlePage5: 'Nun müssen wir die Artikelgruppen den Konsumationsarten zuweisen',
                        textPage5: 'Es müssen alle Artikelgruppen einer Konsumationsart hinzugefügt werden. Nicht alle Artikelgruppen gefunden? Überprüfe die Einstellungen deiner HelloTess! Systeme und versuche es nochmals.',
                        titlePage6: 'Fast fertig!',
                        textPage6: 'Drücke “Abschliessen” wenn du dir sicher bist, dass diese Einstellungen so korrekt sind.',
                    },
                },
                fiduciaryInformation: {
                    title: 'Monatsexport mit HelloTESS',
                    description: 'Definiere hier, basierend auf den Outlets und Ertragssparten im HelloTESS, deine verschiedenen Kontonummern pro Restaurant.',
                    transitoryAccount: 'Durchlaufkonto',
                    transitoryAccountDescription: 'Sämtliche Kontobestände werden im Monatsabschluss mithilfe dieses Kontos gebucht.',
                    account: 'Dein Konti',
                },
                final: {
                    step: {
                        title: 'Zusammenfassung',
                        description: 'Mit folgenden Informationen zu deinem Benutzeraccount und deinem ersten Restaurant startest du nun GastroBuddy.',
                    },
                },
            },
            welcomeConfirm: {
                title: 'Willkommen bei GastroBuddy!',
                text: 'Du hast das SetUp von Gastrobuddy erfolgreich abgeschlossen.',
                confirmButton: 'Weiter',
            },
            pages: {
                dashboard: {
                    title: 'Tagesabschluss',
                    dailyTurnover: 'Tagesumsatz',
                    outlets: 'Outlets',
                    digitalPaymentMethods: 'Zahlungsarten Digital',
                    cashPaymentMethods: 'Zahlungsarten Bar',
                    cashExpenses: 'Kassenauslagen',
                    cashDebit: 'Bargeld Soll CHF',
                    cancelDailyRecordsTitle: 'Änderungen verwerfen?',
                    cancelDailyRecordsText: 'Wenn du auf «Änderungen verwerfen» klickst, werden sämtliche Änderungen welche seit dem letzten speichern gemacht wurden, verworfen.',
                    cancelDailyRecordsCancelBtn: 'Zurück',
                    cancelDailyRecordsSubmitBtn: 'Änderungen verwerfen',
                    outletsTooltip: 'Importieren sie zuerst den Tagesumsatz',
                },
                analytics: {
                    title: 'Monatsübersicht',
                    paymentTypeHeading: 'Zahlungsarten',
                    outletHeading: 'Outlets',
                    dailyHeading: 'Umsatz pro Tag',
                    totalHeading: 'Monatsumsatz',
                    buttons: {
                        closeMonth: 'Monat abschliessen',
                        exportMonth: 'Monat exportieren',
                        exportToInfoniqa: 'Infoniqa ONE 50 export',
                    },
                    infoniqa: {
                        title: 'Wähle den Mandanten aus',
                        text: 'Auf welchen Mandanten soll dieser Monat exportiert werden?',
                    },
                },
                restaurants: {
                    title: 'Restaurants',
                    addButton: 'Restaurant hinzufügen',
                    addEditRestaurantTitle: 'Einstellungen',
                    tabs: {
                        restaurantDetails: 'Restaurantangaben',
                        paymentMethods: 'Zahlungsarten',
                        wordlineTerminals: 'Terminals',
                        hellotessTerminals: 'Kassensysteme',
                        fiduciaryInformation: 'FIBU-Kontierung',
                    },
                    dailyCycle: 'Tageszyklus',
                    dailyCycleDescription: 'Der Tageszyklus bestimmt den 24h Takt dieser Applikation. Ihr gewünschter Tageszyklus beginnt um folgende Uhrzeit.',
                    hellotess: {
                        successfullyConnected: 'Dein HelloTESS! API Schlüssel wird geprüft',
                        welcome: 'Willkommen beim HelloTess! Set-Up Prozess.',
                        welcomeDescription: 'Bitte wähle dein Restaurant aus',
                        restaurantChooserLabel: 'Restaurant',
                        addOutlet: 'Outlet hinzufügen',
                        addConsumptionType: 'Konsumationsart hinzufügen',
                        integrationSteps: {
                            outlets: {
                                title: 'Outlets',
                                headingDescription: 'Es wurden folgende Kassen gefunden',
                                description: 'Im nächsten Schritt kannst du die oben genannten Kassen den unteren Outlets hinzufügen.',
                            },
                            outletsMapping: {
                                title: 'Nun müssen wir die Kassensysteme den Outlets zuweisen',
                                description: 'Es müssen alle Kassensysteme einem Outlet hinzugefügt werden. Nicht alle Kassensysteme gefunden? Überprüfe die Einstellungen deiner HelloTess! Systeme und versuche es nochmals.',
                            },
                            consumptionTypes: {
                                title: 'Definieren wir nun die relevanten Konsumationsarten.',
                                headingDescription: 'Es wurden folgende Artikelgruppen gefunden:',
                                description: 'Im nächsten Schritt kannst du die oben genannten Artikelgruppen den unteren Konsumationsarten hinzufügen.',
                            },
                            consumptionTypesMapping: {
                                title: 'Nun müssen wir die Artikelgruppen den Konsumationsarten zuweisen',
                                description: 'Es müssen alle Artikelgruppen einer Konsumationsart hinzugefügt werden. Nicht alle Artikelgruppen gefunden? Überprüfe die Einstellungen deiner HelloTess! Systeme und versuche es nochmals.',
                            },
                            webhook: {
                                title: 'Um aktuelle Daten über dein HelloTESS System zu bekommen, musst du in deiner Kasse einen Webhook einrichten.',
                                description: 'Dies kannst du im Admin Pannel unter Export und dann API Notifications einrichten. Mehr Informationen findest du in der HelloTESS Dokumentation. Bitte gebe die folgenden Daten ein:',
                            },
                            final: {
                                title: 'Fast fertig!',
                                description: 'Drücke “Abschliessen” wenn du dir sicher bist, dass diese Einstellungen so korrekt sind.',
                            },
                        },
                        outletsNotUsed: 'Die folgenden Outlets wurden nicht benutzt',
                        outletsNotMapped: 'Kein Kassensystem wurde den folgenden Outlets hinzugefügt.',
                        consumptionTypesNotUsed: 'Die folgenden Konsumationsarten wurden nicht benutzt',
                        consumptionTypesNotMapped: 'Kein Artikelgruppen wurde den folgenden Konsumationsarten hinzugefügt.',
                    },
                },
                globalSettings: {
                    title: 'Globale Einstellungen',
                    tabs: {
                        trusteeInformation: 'Treuhänderangaben',
                        monthlyExport: 'Monatsexport',
                        intercompanyMovements: 'Intercompany Warenverschiebung',
                    },
                    intercompanyMovements: {
                        heading: 'Intercompany Warenverschiebung',
                        description: 'Definiere hier die Globalen Einstellungen für die Intercompany Warenverschiebung',
                        operatingAccounts: {
                            heading: 'Betriebskonti',
                            description: 'Hier sind sämtliche Betriebe welche mit deinem Konto verknüpft sind. Diese Konti werden in den Buchungen der Intercompany Warenverschiebung benutzt.',
                        },
                        expenseAccounts: {
                            heading: 'Aufwandskonti',
                            description: 'Auf diese Kontos können die Intercompany Warenverschiebungen gebucht werden.',
                            globalAccounts: 'Globale Einstellungen',
                            addExpenseAccount: 'Aufwandskonto hinzufügen',
                        },
                    },
                },
                users: {
                    title: 'Benutzerverwaltung',
                    addButton: 'Benutzer hinzufügen',
                    newUserTitle: 'Neuer Benutzer hinzufügen',
                    editUserTitle: 'editieren',
                    userFormTitle: 'Benutzerangaben',
                },
                cash: {
                    title: 'Kassenbuch',
                    excelExport: 'Excel exportieren',
                    totalCashDeposits: 'Total Barauslagen CHF',
                },
                notifications: {
                    title: 'Notificationcenter',
                },
                intercompanyMovements: {
                    title: 'Intercompany Warenverschiebung',
                    addNew: 'Neuer Eintrag hinzufügen',
                    labels: {
                        date: 'DD.MM.YYYY',
                        dateFrom: 'Datum Von',
                        dateTo: 'Datum Bis',
                        from: 'Betrieb Von',
                        to: 'Betrieb Nach',
                        revenueDivision: 'Sparte',
                        number: '0000',
                        description: 'Beschreibung',
                        dateString: 'Datum',
                        expense: 'Aufwand',
                        quantity: 'Menge',
                        price: 'Preis pro Stk.',
                        total: 'Total',
                        filter: 'Filter',
                        reset: 'Zurücksetzen',
                    },
                    deletePopup: {
                        contentTitle: 'Bist du sicher?',
                        contentText: 'Möchtest du diesen Eintrag wirklich löschen?',
                        cancelText: 'Abbrechen',
                        confirmText: 'Löschen',
                    },
                },
                selectRestaurant: {
                    title: 'Welche Restaurantdaten möchtest du zuerst einsehen?',
                    selectFieldLabel: 'Restaurant',
                    createTitle: 'Erstelle dein erstes Restaurant',
                    createText: 'Um Gastrobuddy zu nutzen musst du ein Restaurant erstellen. Gebe dazu hier die Informationen deines Restaurants ein.',
                    createSend: 'Erstellen',
                },
                selectTenant: {
                    title: 'Wähle deine gewünschte Organisation',
                    selectFieldLabel: 'Organisation',
                },
            },
            validations: {
                restaurant: {
                    name: {
                        isNotEmpty: 'Name is required',
                        isString: 'Name must be a string',
                    },
                    street: {
                        isNotEmpty: 'Street is required',
                        isString: 'Street must be a string',
                    },
                    streetNumber: {
                        isNotEmpty: 'Street number is required',
                        isNumber: 'Street number must be a number',
                    },
                    postcode: {
                        isNotEmpty: 'Postcode is required',
                        isNumber: 'Postcode must be a number',
                    },
                    city: {
                        isNotEmpty: 'City is required',
                        isString: 'City must be a string',
                    },
                    country: {
                        isNotEmpty: 'Country is required',
                        isString: 'Country must be a string',
                    },
                    backOfficeURL: {
                        isNotEmpty: 'Backoffice-URL ist erforderlich',
                    },
                    backOfficeAPIKey: {
                        isNotEmpty: 'API-Schlüssel ist erforderlich',
                    },
                    apiKeyName: {
                        isNotEmpty: 'API-Schlüssel name ist erforderlich',
                    },
                    apiKeyDescription: {
                        isNotEmpty: 'API-Schlüssel description ist erforderlich',
                    },
                },
                user: {
                    lastname: {
                        isNotEmpty: 'Lastname is required',
                        isString: 'Lastname must be a string',
                    },
                    firstname: {
                        isNotEmpty: 'Firstname is required',
                        isString: 'Firstname must be a string',
                    },
                    email: {
                        isNotEmpty: 'Email is required',
                        isEmail: 'Email must be a valid email',
                    },
                    username: {
                        isNotEmpty: 'Username is required',
                        isString: 'Username must be a string',
                    },
                    password: {
                        isNotEmpty: 'Password is required',
                        isPassword: 'Password must contain at least one number, one uppercase letter and one lowercase letter and one special character',
                        minLength: 'Password must be at least 8 characters long',
                        maxLength: 'Password must be at most 20 characters long',
                    },
                    confirmPassword: {
                        minLength: 'Confirm password must be at least 8 characters long',
                        isSameAs: 'Confirm password must be the same as password',
                    },
                    tenant: {
                        isNotEmpty: 'Ein Gruppenname ist erforderlich',
                    },
                    role: {
                        isNotEmpty: 'Role is required',
                    },
                },
                integrations: {
                    apiKey: 'Bitte geben Sie einen API-Key ein',
                    url: 'Bitte geben Sie eine URL ein',
                },
            },
        },
    },
}));
